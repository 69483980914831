import type { SVGProps } from 'react';

export const Logo = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={37}
    height={42}
    fill='none'
    viewBox='0 0 37 42'
    {...props}
  >
    <path
      fill='url(#logo_svg__a)'
      fillRule='evenodd'
      d='M19.57 16.511c-3.24-.698-6.049-.175-8.273 1.098l-.013.002.013-.007v.004a10.55 10.55 0 0 1 2.856-1.12s-3.072.176-5.464 1.729l-.03.007a.73.73 0 0 0-.109.085c-.969.659-1.81 1.552-2.267 2.764a39.832 39.832 0 0 1-1.319-3.941c3.835-2.807 10.86-2.92 14.607-.62Z'
      clipRule='evenodd'
    />
    <path
      fill='url(#logo_svg__b)'
      fillRule='evenodd'
      d='M11.297 17.608c.889-.509 1.852-.886 2.857-1.12 0 0-6.287.36-7.875 4.595v.015c-.239.784.468 2.838.468 2.838l1.816 1.924c-1.413-5.598 2.325-8.013 2.734-8.256v.005Z'
      clipRule='evenodd'
    />
    <path
      fill='url(#logo_svg__c)'
      fillRule='evenodd'
      d='M13.798 8.699a9.318 9.318 0 0 1-1.184-1.567 4.178 4.178 0 0 0 .39 1.811c.176.394.407.763.688 1.096.35.117.936.327 1.528.528l1.933.673c-.53-.264-1.035-.57-1.513-.915A11.52 11.52 0 0 1 13.798 8.7Z'
      clipRule='evenodd'
    />
    <path
      fill='url(#logo_svg__d)'
      fillRule='evenodd'
      d='M10.107 10.274c-.562-.051-.929-.06-.929-.06s.172.193.356.41c.106.133.212.272.312.421.697-.134 2.93-.262 2.93-.262a12.983 12.983 0 0 0-2.67-.509Z'
      clipRule='evenodd'
    />
    <path
      fill='url(#logo_svg__e)'
      fillRule='evenodd'
      d='M5.692 13.093s.43-.12.973-.252a16.434 16.434 0 0 1 1.475-.302s-.387-.102-.937-.19c-.624-.096-1.47-.173-2.185-.051.264.231.491.5.674.795Z'
      clipRule='evenodd'
    />
    <path fill='#270C59' d='M23.052 10.199h-.001l.001.001V10.2Z' />
    <path
      fill='url(#logo_svg__f)'
      d='M19.967 37.087a10.538 10.538 0 0 1-3.381-1.221 11.317 11.317 0 0 1-4.407-4.666c-4.86-9.437 3.984-16.254 13.246-12.375h.016s-1.291.302-2.151.919c1.853-.319 3.547-.088 4.995.287 0 0 .856-1.91 1.015-2.407.16-.498-.234-1.463-.526-1.976a2.249 2.249 0 0 1-.165.712l-1.718-1.082c.423-1.611-2.985-1.55-3.85-5.084 0 .121-.071 1.83.993 2.652l-.977-.53-.014-.036c-.075-.027-2.966-1.087-4.84-2.415 1.277 1.499 3.012 1.837 2.81 3.09l1.718 1.083c.093-.228.15-.469.165-.714.292.515.684 1.48.525 1.977-.16.498-1.013 2.407-1.013 2.407-1.456-.374-3.15-.603-4.996-.288.86-.62 2.15-.918 2.15-.918-3.234-.698-6.042-.175-8.275 1.098v-.02c-.409.246-4.146 2.664-2.733 8.258l2.113 3.59c.937 1.883 3.307 5.282 4.269 6.207 1.73 1.674 4.458 3.347 4.458 3.347'
    />
    <path
      fill='url(#logo_svg__g)'
      d='M24.045 12.852c-1.064-.823-.999-2.531-.993-2.652a2.64 2.64 0 0 0 .007 2.089l.014.036.972.527Z'
    />
    <path
      fill='url(#logo_svg__h)'
      d='M15.239 26.687v-.027c-1.763-4.277 1.667-9.81 10.199-7.835-9.263-3.876-18.755 2.869-13.892 12.302.974 1.933 3.015 4.018 4.918 5.131 1.044.596 2.264 1.207 3.516.829a40.371 40.371 0 0 0 4.224-4.015c-.943-.012-1.88-.15-2.785-.41-4.558-1.325-6.076-5.656-6.18-5.975Z'
    />
    <path
      fill='#fff'
      fillRule='evenodd'
      d='M.996 8.104a40.944 40.944 0 0 0 5.021 18.484c3.12 5.707 7.557 10.643 12.968 14.425 5.41-3.783 9.847-8.719 12.966-14.426a40.958 40.958 0 0 0 5.023-18.483 39.21 39.21 0 0 0-11.397-3.788c3.244 1.59 6.445 3.542 8.722 5.297l-.02.302a38.53 38.53 0 0 1-4.543 15.36A39.832 39.832 0 0 1 19.274 37.64l-.28.221-.282-.221A39.834 39.834 0 0 1 8.246 25.273 38.532 38.532 0 0 1 3.701 9.91l-.02-.301A25.027 25.027 0 0 1 8.95 5.056a39.557 39.557 0 0 0-7.94 3.048H.995Z'
      clipRule='evenodd'
    />
    <path
      fill='#fff'
      d='M21.575.718s-.11 1.811.993 2.66l-.975-.53v-.012C20.143 2.184 18.235 1.07 17.035 0c0 .062-.007.124-.007.186 0-.062 0-.124.008-.186 0 0 .936 2.415 3.695 3.28l-2.618-.36s-2.298-.564-4.514.165c0 0 .862-.03 3.213.487 0 0-1.831.2-2.538.349-.075 0-2.984.117-4.83 1.242l.012.009c1.42-.302 3.366-.133 3.366-.133-.812.211-2.703.93-2.703.93v-.01c-.107.032-2.483.755-4.04 2.463a19.287 19.287 0 0 1 2.467-.837c-.59.417-1.143.879-1.657 1.38 0 0-1.592 1.53-2.861 4.336l.156.391a9.963 9.963 0 0 1 1.513-.616 3.537 3.537 0 0 0-.675-.795c1.144-.697 2.696-1.005 3.73-1.14.257-.182.521-.355.788-.523l-.356-.41a7.45 7.45 0 0 1 1.67-.337 16.713 16.713 0 0 1 2.155-.923 4.155 4.155 0 0 1-.395-1.816c.589.513 1.22.977 1.89 1.388.835-.2 1.689-.33 2.549-.386v.01c.027-.103.063-.204.109-.301.025.096.055.19.083.282 2.309-.117 4.613.311 6.713 1.247h.033H23.97c-.139.033-1.327.33-2.135.914 1.853-.318 3.545-.087 4.995.287 0 0 .856-1.91 1.015-2.415.16-.505-.234-1.462-.526-1.975a2.282 2.282 0 0 1-.156.714l-1.717-1.084c.44-1.775-3.08-1.683-3.861-5.122'
    />
    <path
      fill='url(#logo_svg__i)'
      fillRule='evenodd'
      d='M17.026.005c0 .03-.002.062-.004.092a1.44 1.44 0 0 0-.004.093c0 .815.363 1.733.851 2.43.077.107.156.21.234.302l2.618.359C17.966 2.41 17.026.005 17.026.005Zm5.542 3.373c-1.103-.853-.993-2.66-.993-2.66a2.496 2.496 0 0 0-.207 1.104c.01.349.086.693.225 1.015v.012l.975.529ZM6.065 8.416a19.29 19.29 0 0 1 2.48-.831c-.588.417-1.142.879-1.656 1.38a2.136 2.136 0 0 0-.824-.549Zm6.744-3.377s-1.945-.17-3.37.131c.26.23.484.494.663.787v.009s1.896-.716 2.707-.927Zm.781-1.95c.017 0 .882-.025 3.213.488 0 0-1.832.2-2.539.344a8.472 8.472 0 0 0-.674-.832Z'
      clipRule='evenodd'
    />
    <path
      fill='url(#logo_svg__j)'
      fillRule='evenodd'
      d='M24.183 33.169a39.612 39.612 0 0 0 5.25-7.266l-.053-.058c-4.337 6.308-12.462 5.066-14.165.93 0 0 1.454 4.62 6.175 5.994.907.26 1.846.398 2.793.41v-.01Z'
      clipRule='evenodd'
    />
    <path
      fill='url(#logo_svg__k)'
      fillRule='evenodd'
      d='M17.159 7.847a2.667 2.667 0 0 0 .016 2.125l.977.528c-1.105-.85-.993-2.659-.993-2.659v.006Z'
      clipRule='evenodd'
    />
    <path
      fill='url(#logo_svg__l)'
      d='m23.97 9.38-.003-.002h-.006c-2.253-.485-4.298-.41-6.08.07a6.433 6.433 0 0 1-.168-.265l-.023-.04a3.37 3.37 0 0 1-.174-.322 5.269 5.269 0 0 1-.28-.697c2.308-.117 4.612.31 6.712 1.246l.019.008h.003v.001Z'
    />
    <path
      fill='url(#logo_svg__m)'
      d='M14.503 8.517h.011c.289.183.575.35.843.504l.013.008.312.17h.01l.146.08.047.026.21.112.093.048.114.06.13.067.06.03.156.076.036.018.003.001c.047.024.092.046.135.065.084-.033.171-.063.259-.093a2.566 2.566 0 0 1-.061-.227l-.014-.074c-.01-.048-.019-.095-.025-.142l-.006-.042-.005-.04a4.441 4.441 0 0 1-.01-.129.351.351 0 0 1-.002-.053l.001-.02v-.349c0-.03 0-.058.01-.085.004-.013.004-.025.004-.037a.1.1 0 0 1 .006-.04c.008-.018.009-.035.01-.053v-.011l.001-.013c.003-.02.008-.041.013-.062l.004-.016a.82.82 0 0 0 .009-.04l.005-.024.002-.012.019-.072v-.001l.006-.022v-.01c-.859.061-1.71.196-2.545.402Z'
    />
    <path
      fill='url(#logo_svg__n)'
      d='M10.844 9.877h.002a16.724 16.724 0 0 1 2.15-.922 4.582 4.582 0 0 0 .684 1.09h-.01a9.729 9.729 0 0 0-2.825-.168l-.003.002.002-.002Z'
    />
    <path
      fill='url(#logo_svg__o)'
      d='M9.854 11.045s-.451.015-1.108.101c.257-.18.521-.354.796-.522l.02.026.03.04c.085.11.177.23.262.355Z'
    />
    <defs>
      <linearGradient
        id='logo_svg__a'
        x1={4.599}
        x2={15.049}
        y1={17.545}
        y2={16.254}
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#fff' />
        <stop offset={1} stopColor='#fff' stopOpacity={0} />
      </linearGradient>
      <linearGradient
        id='logo_svg__b'
        x1={6.16}
        x2={11.128}
        y1={24.336}
        y2={18.886}
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#fff' />
        <stop offset={1} stopColor='#fff' stopOpacity={0} />
      </linearGradient>
      <linearGradient
        id='logo_svg__c'
        x1={2151.8}
        x2={2283.84}
        y1={605.519}
        y2={605.519}
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#fff' />
        <stop offset={1} stopColor='#fff' />
      </linearGradient>
      <linearGradient
        id='logo_svg__d'
        x1={1626.12}
        x2={1709.21}
        y1={139.241}
        y2={139.241}
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#fff' />
        <stop offset={1} stopColor='#fff' />
      </linearGradient>
      <linearGradient
        id='logo_svg__e'
        x1={1324.85}
        x2={1387.3}
        y1={156.983}
        y2={156.983}
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#fff' />
        <stop offset={1} stopColor='#fff' />
      </linearGradient>
      <linearGradient
        id='logo_svg__f'
        x1={9994.19}
        x2={13786.5}
        y1={4263.27}
        y2={9931.45}
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#fff' />
        <stop offset={1} stopColor='#fff' />
      </linearGradient>
      <linearGradient
        id='logo_svg__g'
        x1={23.936}
        x2={22.086}
        y1={12.248}
        y2={10.017}
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#fff' />
        <stop offset={1} stopColor='#fff' stopOpacity={0.1} />
      </linearGradient>
      <linearGradient
        id='logo_svg__h'
        x1={16.834}
        x2={19.309}
        y1={18.737}
        y2={27.789}
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#fff' stopOpacity={0.1} />
        <stop offset={0.9} stopColor='#fff' />
      </linearGradient>
      <linearGradient
        id='logo_svg__i'
        x1={14.317}
        x2={16.405}
        y1={0.005}
        y2={8.374}
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#fff' />
        <stop offset={1} stopColor='#fff' stopOpacity={0} />
      </linearGradient>
      <linearGradient
        id='logo_svg__j'
        x1={18.197}
        x2={25.765}
        y1={29.44}
        y2={34.58}
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#fff' />
        <stop offset={1} stopColor='#fff' stopOpacity={0} />
      </linearGradient>
      <linearGradient
        id='logo_svg__k'
        x1={16.948}
        x2={18.152}
        y1={9.171}
        y2={9.171}
        gradientUnits='userSpaceOnUse'
      >
        <stop offset={0.39} stopColor='#fff' />
        <stop offset={1} stopColor='#fff' />
      </linearGradient>
      <linearGradient
        id='logo_svg__l'
        x1={13.81}
        x2={18.848}
        y1={4.868}
        y2={14.219}
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#fff' />
        <stop offset={1} stopColor='#fff' stopOpacity={0} />
      </linearGradient>
      <linearGradient
        id='logo_svg__m'
        x1={13.81}
        x2={18.848}
        y1={4.868}
        y2={14.219}
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#fff' />
        <stop offset={1} stopColor='#fff' stopOpacity={0} />
      </linearGradient>
      <linearGradient
        id='logo_svg__n'
        x1={13.81}
        x2={18.848}
        y1={4.868}
        y2={14.219}
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#fff' />
        <stop offset={1} stopColor='#fff' stopOpacity={0} />
      </linearGradient>
      <linearGradient
        id='logo_svg__o'
        x1={13.81}
        x2={18.848}
        y1={4.868}
        y2={14.219}
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#fff' />
        <stop offset={1} stopColor='#fff' stopOpacity={0} />
      </linearGradient>
    </defs>
  </svg>
);
